import { Directive, inject, input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

import { SlLetContext } from './let.model';

@Directive({
	selector: '[slLet]',
	standalone: true
})
export class LetDirective implements OnChanges {
	readonly slLetSignal$ = input(null, { alias: 'slLet' });

	readonly #viewContainerRef = inject(ViewContainerRef);
	readonly #templateRef = inject(TemplateRef) as TemplateRef<SlLetContext>;

	ngOnChanges(): void {
		this.#viewContainerRef.clear();
		this.#viewContainerRef.createEmbeddedView(this.#templateRef, {
			$implicit: this.slLetSignal$(),
			slLet: this.slLetSignal$()
		});
	}
}
